import { createStore } from 'vuex'
import languages from "@/schemas/content";
import returnsApi from "@/controllers/returnsController";
import shopsApi from "@/controllers/shopsController";
import { formatJson } from "@/helpers/formatters";

const toBoolean = (value) => {
  // If is yes/no, cast to boolean
  if (typeof value === "string" && ["yes", "no"].includes(value)) {
    return value === "yes";
  }

  return value;
};

export const store = createStore({
  state: {
    userData: null,
    shops: [],
    shopData: null,
    notes: null,
    deviants: null,
    language: "en",
    toast: "",
    toastType: "default",
    returnData: null,
    returnSearchResults: null,
    selectedId: null,
    token: null,
  },
  mutations: {
    //update order state and save to local storage
    updateData: function (state, data) {
      var options = {
        name: data.name || false, //required
        data: data.data || false, //required
        save: data.save || false,
      };

      state[options.name] = options.data;

      if (options.save) {
        localStorage.setItem(options.name, JSON.stringify(options.data));
      }
    },
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    clearData(state, name) {
      if (localStorage.getItem(name)) {
        localStorage.removeItem(name);
      }

      if (state[name]) {
        state[name] = null;
      }
    },
    setToast(state, toast) {
      state.toast = toast;
    },
    setToastType(state, type) {
      state.toastType = type;
    },
    setReturn(state, data) {
      data.shopSettings = formatJson(data.shop.settings, "key");
      state.returnData = data;
    },
    updateShops(state, newShop) {
      state.shops.push(newShop);
    },
    updateUsers(state, changedUsers) {
      const newAccountOwner = state.shopData.users.find(
        (user) => user.id === changedUsers.newAccountOwner.id
      );
      Object.assign(newAccountOwner, changedUsers.newAccountOwner);
      if (changedUsers.previousAccountOwner) {
        const previousAccountOwner = state.shopData.users.find(
          (user) => user.id === changedUsers.previousAccountOwner.id
        );
        Object.assign(previousAccountOwner, changedUsers.previousAccountOwner);
      }
    },
  },
  actions: {
    makeToast: function (context, { message, type = 'default' }) {
      context.commit("setToastType", type);
      context.commit("setToast", message);
    },
    // To add shops in an "onboarding" state to enable app store download
    async addNewShop(context, { newShopName, newShopShopifyDomain }) {
      const request = {
        name: newShopName,
        state: "onboarding",
        nonce: "dummy",
        provider_domain: newShopShopifyDomain,
      };
      try {
        const newShop = await shopsApi.addShop(request);
        context.commit(
          "setToast",
          "Shop added successfully.\nThe shop will appear in the list above once they have completed onboarding."
        );
        context.commit("updateShops", newShop);
      } catch (error) {
        context.commit("setToast", error.response.data.message);
      }
    },
    selectAutoCompleteId: async function ({ commit }, id) {
      commit("setProperty", {
        key: "selectedId",
        value: id,
      });
    },
    async switchAccountOwner(context, { payload, shopId }) {
      try {
        const changedUsers = await shopsApi.changeAccountOwner(payload, shopId);
        context.commit("setToast", "Account owner changed successfully.");
        context.commit("updateUsers", changedUsers);
      } catch (error) {
        context.commit("setToast", "Error changing account owner.");
      }
    },
  },
  getters: {
    settings(state) {
      // Create an object of settings so we can access settings by key
      if (state.shopData && state.shopData.settings) {
        return state.shopData.settings.reduce((accumulator, current) => {
          return {
            ...accumulator,
            [current.key]: {
              data: current,
              value: toBoolean(current.value),
            },
          };
        }, {});
      }
      return {};
    },
    getAutocompleteSelected: (state) => () => {
      return state.selectedId;
    },
    getSetting: (state, getters) => (setting) => {
      return getters.settings[setting] ? getters.settings[setting].value : null;
    },
  },
});