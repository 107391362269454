export const capitalize = (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formatJson = (data, key) => {
  const formatted = {};
  if (Array.isArray(data)) {
    for (const element of data) {
      formatted[element[key]] = element;
    }
  } else {
    for (const property in data) {
      const newKey = data[property][key];
      formatted[newKey] = data[property];
    }
  }
  return formatted;
};

export const getStateColor = (state) => {
  const map = {
    active: "success",
    registered: "alert",
    delinquent: "warning",
    nope: "critical",
  };

  return map[state] ?? "default";
};

export const toggleIcon = `
    <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.34292 7.75734L4.92871 9.17155L11.9998 16.2426L19.0708 9.17158L17.6566 7.75737L11.9998 13.4142L6.34292 7.75734Z"/>
    </svg>
`;
