import { axios } from "../main.ts";

export default {
  addShop: async function (payload) {
    const { data } = await axios.post("/api/shop", payload);
    return data;
  },
  getLogs: async function (query) {
    const { data } = await axios.get(
      `/api/shops/${query.shopId}/datadog-logs/${query.time}`
    );
    return data.data;
  },
  changeAccountOwner: async function (payload, shopId) {
    const { data } = await axios.post(
      `/api/shops/${shopId}/users/change-account-owner`,
      payload
    );
    return data;
  },
};
