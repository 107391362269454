// import $ from 'jquery'
import { format } from 'date-fns';

export default {

  filters: {

    activeParse: function(value){
      switch(value) {
      case 1:
        return 'Active';
      }
      return 'Disabled'
    },
    planFilter: function(value){
      switch(value) {
      case 1:
        return 'Loop Plus';
      case 2:
        return 'Loop';
      default:
        return 'error'
      }
    }
  },

  methods: {
    dateFormatter: function(value){
      return format(new Date(value), 'MMM-dd-yyyy');
    },

    numberWithCommas: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    imageBackup:function(event){
      event.target.src="img/error.svg"
    },

    booleanFilter: function(value){
      return value === 'yes';
    },

    getStore: function (storeName) {
      if(localStorage[storeName] !== undefined){
        return JSON.parse(localStorage.getItem(storeName || null));
      }
    },

    setStore: function (storeName, storeData) {
      if(storeData !== undefined){
        localStorage.setItem(storeName, JSON.stringify(storeData));
      }
    },

    killStore: function (storeName) {
      localStorage.removeItem(storeName);
    },

    returnStatusColor(state) {
      return state === "open" ? "success" : "alert";
    },

    boolColor(datum) {
      return datum === true ? "success" : "critical";
    },

    reverseBoolColor(datum) {
      return datum === true ? "critical" : "success";
    },

    flagAlertColor(datum) {
      return datum === true ? "alert" : "success";
    },

    processStatusColor(status) {
      switch(status) {
      case "fulfilled": return "success";
      case "partial": return "warning";
      case "processed": return "success";
      case "success": return "success";
      case "succeeded": return "success";
      case "closed": return "success";
      case "cancelled": return "warning";
      case "processing": return "alert";
      case "error": return "critical";
      }
      return "default";
    },

    processRecalculateColor(status) {
      switch (status) {
      case "closed":
      case "complete":
      case "processed":
      case "default":
        return "success";
      case "open":
      case "incomplete":
        return "warning";
      case "processing":
        return "alert";
      case "error":
        return "critical";
      }
      return "default";
    }

  },


  adminLink: function(shopId, url = null) {
    var admin = `${import.meta.env.VITE_API}/api/shop-proxy`;
    if (url) {
      var intended = btoa(url).replace(/\+/g, '-').replace(/\//g, '_');
      return `${admin}/${shopId}/${intended}`;
    }
    return `${admin}/${shopId}`;
  }
}
