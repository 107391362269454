<template>
  <div class="app">
    <nav-bar />
    <router-view />

    <portal-target
      name="modal"
      multiple
      :transition="{ name: 'fade-down', mode: 'out-in' }"
    />
    <toast-notification
      :message="toast"
      :type="toastType"
      @hidden="resetToast"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ToastNotification } from '@loophq/component-library';
import util from './js/global.js'
import NavBar from './components/Nav.vue'

export default {
  components: {
    NavBar,
    ToastNotification,
  },
  mixins: [
    util
  ],
  computed: mapState({
    toast: state => state.toast,
    toastType: state => state.toastType
  }),
  created() {
    this.checkStatus();
  },
  methods: {
    checkUserData() {

      if (!this.$store.state.userData) {
        if (this.getStore('userData') !== undefined) {
          this.$store.commit('updateData', {
            name: 'userData',
            data: this.getStore('userData')
          });
          return true;
        }
      }
      return false;
    },

    checkStatus() {
      this.$axios.get('/init').then((response) => {
        if (response.data.user) {
          if (!this.checkUserData()) {
            this.$router.push({name: 'startup'}).catch(() => {
            });
          } else if (this.$route.name === "login" || this.$route.name === "startup") {
            this.$router.push({name: 'home'})
          }
          if (!this.$store.state.token) {
            if (this.getStore('token') !== undefined) {
              this.$store.commit('updateData', {
                name: 'token',
                data: this.getStore('token')
              });
            }
          }
        } else {
          this.killStore('userData');
          this.$router.push({name: 'startup'}).catch(Function.prototype);
        }
      });
    },
    resetToast() {
      this.$store.commit('setToast', '');
      this.$store.commit('setToastType', 'default');
    }
  }
}
</script>

<style lang="scss">

.fade-down-enter-active, .fade-down-leave-active {
  transition: opacity .24s ease, transform .24s ease;
}

.fade-down-enter, .fade-down-leave-to {
  transform: translate3d(0, -6px, 0);
  opacity: 0;
}

</style>
