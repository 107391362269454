import axios from 'axios';

axios.defaults.baseURL = import.meta.env.VITE_API;
axios.defaults.withCredentials = true;

export const plugin = {
  install: (app, options) => {
    app.config.globalProperties.$axios = axios;
    app.provide('axios', options)
  }
}

export { axios }