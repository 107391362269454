<template>
  <ul class="link-list">
    <li
      v-for="link in links"
      :key="link.title"
      :class="{ '--active': isActive(link) }"
    >
      <router-link :to="link.route">
        {{ link.title }}
      </router-link>
    </li>
  </ul>
</template>

<script setup>
import { useRoute } from "vue-router";

const route = useRoute();

defineProps({
  links: {
    type: Array,
    required: true
  }
});

const isActive = (link) => route.path.includes(link.route);
</script>

<style lang="scss" scoped>
.link-list {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  position: relative;

  li {
    margin: 0 0.75rem;
    position: relative;
    opacity: 0.8;
    transition: opacity 0.22s;

    a {
      color: white;
    }

    &.--active,
    &:hover {
      opacity: 1;
    }

    &.--active {
      border-bottom: 2px solid #fff;
    }
  }
}
</style>